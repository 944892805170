body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

p {
  margin-bottom: 1rem;
}

@media (min-width: 1024px) {
  .position-center {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.menu-link-hover {
  position: relative;
}

.menu-link-hover::before {
  content: "";
  display: block;
  background: #faf089;
  position: absolute;
  height: 15px;
  top: 50%;
  left: -10px;
  right: -10px;
  z-index: -1;
  transform: scaleX(0) translateY(-50%);
  transform-origin: left center;
  transition: transform 400ms cubic-bezier(1, 0, 0, 1) 0ms;
}
